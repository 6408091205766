import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SanvalentinoFull from "../components/SanvalentinoFull"
import Form from "../components/form"

const Sanvalentino = () => (
  <Layout>
    <SEO title="Cena di San Valentina - 14 Febbraio 2020" />
    <div className="custom-container evento">
      <div className="row">
        <div className="col-lg-5">
          <SanvalentinoFull />
        </div>
        <div className="col-lg-7">
          <h1>San Valentino</h1>
          <h4>14 Febbraio 2020</h4>
          <section className="contatti">
            <Form formTitle="Prenora Ora" />
          </section>
          
        </div>
      </div>
    </div>
  </Layout>
)

export default Sanvalentino